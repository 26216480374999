import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';
import { ToastMessage } from '@app/constants';
import { showToast } from '@common/design-system/components/molecules';

import createFetchReducer from '../../utils/createFetchReducer';
import entityNormalizer from '../../utils/entityNormalizer';
import ObjectToOptions from '../../utils/ObjectToOptions';

import { GenericEntityState, ResponseAction } from '../types';

import { FIRST_PAGE } from '../constants';

import {
	saveAudienceTypes,
	getAudienceTypes,
	getMappedContactListsTypes,
	updateAudienceTypes,
	deleteAudienceTypes,
	getAudienceOptionsTypes,
	getSingleAudienceTypes,
	syncAudienceWithIntegrationTypes,
	createAudienceSegmentsTypes,
	editAudienceSegmentTypes,
	saveAudienceV2Types,
	createAudienceSegmentsTypesV2,
	editAudienceSegmentTypesV2,
	verifyAudienceAddressTypes,
	getAudienceFilterOptionTypes,
} from './action';

const audienceType = entityNormalizer('audience');
export const pagination = { audience: [audienceType] };

const initialState: GenericEntityState = {
	loading: true,
	error: {},
	response: {
		status: null,
		message: null,
	},
	audience: {},
	audienceCount: 0,
	pagination: {
		pages: {},
		currentPage: 1,
		totalPages: null,
		count: null,
	},
	audienceBuildingCount: null,
	audienceOptions: [],
	audienceFiltersOptions: {
		actionRequiredAudiences: [],
		draftAudiences: [],
		inactiveAudiences: [],
		runningAudiences: [],
		scheduledAudiences: [],
		sentAudiences: [],
	},
	custom: {
		uploadAudienceV2Loading: false,
		deleteAudienceLoading: false,
	},
};

export type audienceStateType = typeof initialState;

export const paginationSuccessMapper = function paginationMapper(
	state: audienceStateType,
	action: ResponseAction,
): void {
	const {
		response: {
			audience,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
		payload,
	} = action;

	if (!!payload?.custom) {
		return;
	}

	const normalizedData = normalize(
		{ audience, page, pageSize, totalPages, count },
		pagination,
	);

	// to make the infinite scroll work
	if (action.response.page === FIRST_PAGE) {
		state.audience = {
			...normalizedData.entities.audience,
		};
	} else {
		state.audience = {
			...state.audience,
			...normalizedData.entities.audience,
		};
	}

	state.audienceCount = Object.keys(state.audience || {})?.length;

	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.audience;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
		state.pagination.totalTabCount = action.response?.totalTabCount;
	}
};

function updateMapperSuccess(state: audienceStateType, action: ResponseAction) {
	const {
		response: {
			audience: { id, _id },
			audience,
		},
	} = action;
	const audienceId = id || _id;
	state.audience[audienceId] = audience;
}

function deleteMapperSuccess(state: audienceStateType, action: ResponseAction) {
	const {
		response: { audienceId },
	} = action;
	const audienceCopy = { ...state.audience };
	const { [audienceId]: deletedAudienceId, ...restOfAudience } = audienceCopy;
	state.audience = restOfAudience;
}

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			audience,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ audience, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function optionsMapper(state: audienceStateType, action: ResponseAction) {
	const normalizedData: any = normalizeResponse(action);
	const audienceCopy = ObjectToOptions(
		normalizedData?.entities?.audience,
		'id',
		'name',
	);
	audienceCopy?.unshift({
		value: '',
		label: 'All',
	});
	state.audienceOptions = audienceCopy?.length > 1 ? audienceCopy : [];
}

function saveAudienceMapper(state: audienceStateType, action: ResponseAction) {
	const {
		response: { audience },
	} = action;
	state.audience = { [audience?._id]: audience, ...state.audience };
}

function createAudienceSegmentsSuccessMapper(
	state: audienceStateType,
	action: ResponseAction,
) {
	const {
		response: { audiences },
	} = action;

	const newAudiences: { [key: string]: any } = {};

	for (let i = 0; i < audiences.length; i++) {
		const audience = audiences[i];
		const audienceId = audience.id || audience._id;

		newAudiences[audienceId] = audience;
	}

	const normalizedData = normalize(
		{ audiences: { ...newAudiences, ...state.audiences } },
		pagination,
	);
	state.audiences = { ...normalizedData.entities.audiences };
}
function createAudienceSegmentsErrorMapper(
	state: audienceStateType,
	action: ResponseAction,
) {
	const errorCode = action?.error?.response?.data?.errorCode;

	const isDuplicateSegmentName = errorCode === 303;

	if (isDuplicateSegmentName) {
		return showToast({
			message: ToastMessage.duplicateSegmentsName,
			type: 'error',
		});
	} else {
		return showToast({ message: ToastMessage.error, type: 'error' });
	}
}

function getSingleAudienceMapper(
	state: audienceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	const newAudience = response.audience;
	const audienceId = response.audience?.id || response.audience?._id;

	const normalizedData = normalize(
		{ audiences: { ...state.audiences, [audienceId]: newAudience } },
		pagination,
	);
	state.audiences = { ...normalizedData.entities.audiences };
}

function getAudienceFilterOptionSuccessMapper(
	state: audienceStateType,
	action: ResponseAction,
) {
	const {
		actionRequiredAudiences,
		draftAudiences,
		inactiveAudiences,
		runningAudiences,
		scheduledAudiences,
		sentAudiences,
	} = action.response;

	state.audienceFiltersOptions = {
		actionRequiredAudiences,
		draftAudiences,
		inactiveAudiences,
		runningAudiences,
		scheduledAudiences,
		sentAudiences,
	};
}
const audienceSlice = createSlice({
	name: 'audience',
	initialState,
	reducers: {
		SET_AUDIENCE_BUILDING_COUNT(
			state: audienceStateType,
			action: { payload: number },
		) {
			state.audienceBuildingCount = action.payload;
		},
		RESET(state: audienceStateType) {
			state.audiences = {};
			state.audience = {};
			state.audienceCount = 0;
			state.pagination = {
				pages: {},
				currentPage: 1,
			};
			state.custom = {};
		},
	},
	extraReducers: {
		...createFetchReducer(saveAudienceTypes, saveAudienceMapper),
		...createFetchReducer(saveAudienceV2Types),
		...createFetchReducer(getAudienceTypes, paginationSuccessMapper),
		...createFetchReducer(getMappedContactListsTypes),
		...createFetchReducer(updateAudienceTypes, updateMapperSuccess),
		...createFetchReducer(deleteAudienceTypes, deleteMapperSuccess),
		...createFetchReducer(getAudienceOptionsTypes, optionsMapper),
		...createFetchReducer(verifyAudienceAddressTypes),
		...createFetchReducer(getSingleAudienceTypes, getSingleAudienceMapper),
		...createFetchReducer(syncAudienceWithIntegrationTypes),
		...createFetchReducer(
			createAudienceSegmentsTypes,
			createAudienceSegmentsSuccessMapper,
			createAudienceSegmentsErrorMapper,
		),
		...createFetchReducer(
			createAudienceSegmentsTypesV2,
			createAudienceSegmentsSuccessMapper,
			createAudienceSegmentsErrorMapper,
		),
		...createFetchReducer(editAudienceSegmentTypes),
		...createFetchReducer(editAudienceSegmentTypesV2),
		...createFetchReducer(
			getAudienceFilterOptionTypes,
			getAudienceFilterOptionSuccessMapper,
		),
	},
});

export default audienceSlice.reducer;
