import React, {
	ForwardRefRenderFunction,
	forwardRef,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import Quill from 'quill';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import * as S from './RichTextEditor.styles';
import useFloatingUi from '@app/hooks/useFloatingUi';
import { FloatingPortal } from '@floating-ui/react';
import { TagsInputTooltip } from '../../atoms/TagsInput/components';
import { OptionsType } from '../../atoms/TagsInput/types';
import { createPortal } from 'react-dom';
import { Button, Text } from '../../atoms';
import {
	checkHasCurlyBracesMergeTags,
	checkHasSquareBracketMergeTags,
	checkMergeTagsHasSpacingWarning,
} from '../../atoms/TagsInput/utils';
import {
	GrammarWarningEnum,
	MERGE_TAG_INVALID_FORMAT_WARNING,
} from '../../atoms/TagsInput/constants';
import { Delta } from 'quill/core';

type PropsType = {
	id?: string;
	readOnly?: boolean;
	initialValue?: any;
	placeholder?: string;
	hasError?: boolean;
	tagOptions?: OptionsType[];
	tagMenuText?: string;
	message?: string;
	noMessages?: boolean;
	onTextChange?: (
		value: {
			html: string;
			string: string;
			delta?: Delta;
		},
		id?: string,
		...QuillArgs: any[]
	) => void;
};

const RichTextEditor = (
	{
		readOnly,
		initialValue,
		onTextChange,
		placeholder,
		id,
		hasError,
		tagOptions = [],
		tagMenuText = '+',
		message,
		noMessages,
	}: PropsType,
	ref: { current: Quill | null } | null,
) => {
	const internalQuillRef = useRef<Quill | null>(null);
	const quillRef = ref || internalQuillRef;

	const containerRef = useRef(null);
	const initialValueRef = useRef(initialValue);
	const onTextChangeRef = useRef(onTextChange);

	const [grammarWarnings, setGrammarWarning] = useState({
		[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND]: false,
		[GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET]: false,
		[GrammarWarningEnum.MERGE_TAG_CURLY_BRACES]: false,
	});

	const handleInputWarnings = (text: string) => {
		const hasSpacingWarning = checkMergeTagsHasSpacingWarning(text);
		const hasSquareBracketMergeTag = checkHasSquareBracketMergeTags(text);
		const hasCurlyBracesMergeTag = checkHasCurlyBracesMergeTags(text);

		const warnings = {
			...grammarWarnings,
			[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND]: hasSpacingWarning,
			[GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET]: hasSquareBracketMergeTag,
			[GrammarWarningEnum.MERGE_TAG_CURLY_BRACES]: hasCurlyBracesMergeTag,
		};

		setGrammarWarning(warnings);
	};

	const grammarWarningsObj = useMemo(() => grammarWarnings, [grammarWarnings]);

	const hasWarning = useMemo(
		() =>
			grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND] ||
			grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET] ||
			grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_CURLY_BRACES],
		[grammarWarningsObj],
	);

	const textsColor = hasError
		? 'error.text.default'
		: hasWarning
			? 'warning.text.default'
			: 'system.text.medium';

	// Insert text into the editor
	const insertTag = (tag: { label: string; value: string }) => {
		setIsTooltipOpened(false);
		const range = quillRef?.current?.selection?.savedRange;

		if (range) {
			quillRef?.current?.insertText(range.index, ` {{{${tag?.value}}}} `); // Insert the text at the current cursor position
		}
	};

	// Merge tags opening using floating UI
	const {
		x,
		y,
		openingReference,
		floatingReference,
		strategy,
		internalRefs,
		isOpen: isTooltipOpened,
		setIsOpen: setIsTooltipOpened,
		getFloatingProps,
		getReferenceProps,
	} = useFloatingUi({
		preferredPlacement: 'bottom-start',
	});

	useLayoutEffect(() => {
		onTextChangeRef.current = onTextChange;
	});

	useEffect(() => {
		if (quillRef && quillRef.current) {
			quillRef.current?.enable(!readOnly);
		}
	}, [quillRef, readOnly]);

	useEffect(() => {
		const container = containerRef.current as unknown as HTMLElement;
		const editorContainer = container?.appendChild(
			container.ownerDocument.createElement('div'),
		);

		const quill = new Quill(editorContainer, {
			theme: 'snow',
			placeholder: placeholder,
			modules: {
				toolbar: {
					container: [
						[{ header: [1, 2, 3, false] }],
						['bold', 'italic', 'underline', 'strike'],
						['link'],
						[{ list: 'ordered' }, { list: 'bullet' }],
					],
				},
			},
		});

		quillRef.current = quill;

		if (initialValueRef.current) {
			const deltaConverted = quill.clipboard.convert({
				html: initialValueRef.current,
			});
			quill.setContents(deltaConverted, 'silent');
		}

		quill.on(Quill.events.TEXT_CHANGE, (...QuillArgs) => {
			onTextChangeRef.current?.(
				{
					html: quillRef?.current?.getSemanticHTML() || '',
					string: quillRef?.current?.getText() || '',
					delta: quillRef?.current?.getContents(),
				},
				id,
				...QuillArgs,
			);

			handleInputWarnings(quillRef?.current?.getText() || '');
		});

		return () => {
			quillRef.current = null;
			container.innerHTML = '';
		};
	}, []);

	const toolBarModule = quillRef?.current?.getModule('toolbar') as any;
	const toolbarContainer = toolBarModule?.container || document.body;

	return (
		<>
			<S.REWrapper ref={containerRef} id={id} hasError={hasError}>
				{!noMessages && (message || hasWarning) && (
					<Text
						size="small"
						lineHeight="large"
						color={textsColor}
						className="mt-2"
					>
						{message ||
							(grammarWarningsObj[GrammarWarningEnum.MERGE_TAG_SPACE_AROUND] &&
								'Please review spacing around merge tag(s)') ||
							((grammarWarningsObj[
								GrammarWarningEnum.MERGE_TAG_SQUARE_BRACKET
							] ||
								grammarWarningsObj[
									GrammarWarningEnum.MERGE_TAG_CURLY_BRACES
								]) &&
								MERGE_TAG_INVALID_FORMAT_WARNING)}
					</Text>
				)}
			</S.REWrapper>

			{/* Insert merge tags button in toolbar through portal */}
			{createPortal(
				<>
					{tagOptions && tagOptions.length ? (
						<span
							className="ql-formats insert-merge-tags"
							ref={openingReference}
							{...getReferenceProps()}
						>
							<Button
								size="large"
								variant="ghost"
								className="w-auto"
								onClick={() => setIsTooltipOpened(true)}
							>
								{`${tagMenuText}`}
							</Button>
						</span>
					) : null}
				</>,
				toolbarContainer,
			)}

			<FloatingPortal>
				{isTooltipOpened && (
					<TagsInputTooltip
						hideTabs={true}
						tooltipRef={floatingReference}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
						}}
						tagOptions={tagOptions as OptionsType[]}
						onSelect={(tag) => insertTag(tag)}
						{...getFloatingProps()}
					/>
				)}
			</FloatingPortal>
		</>
	);
};

export default forwardRef(
	RichTextEditor as ForwardRefRenderFunction<Quill, PropsType>,
);
