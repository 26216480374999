import * as R from 'ramda';
import { contactRecordChange, storytellerRecordChange } from '../utils';
import { IThemeConfig } from '@flatfile/react';
import GoodkindDSTheme from '@common/design-system/global/theme';
import { FlatfileWorkbookFieldsType } from '../types';

export const flatFileKey = '3f39d564-2c19-49e6-ac61-b014459d6cf2';
//TODO use the internal type Quadri created and reformat all these constants
export const getLocationFields = (
	required?: boolean,
): FlatfileWorkbookFieldsType[] => {
	return [
		{
			label: 'Address',
			key: 'internal-address',
			type: 'string',
			constraints: required ? [{ type: 'required' }] : [],
		},
		{
			label: 'City',
			key: 'internal-city',
			type: 'string',
			constraints: required ? [{ type: 'required' }] : [],
		},
		{
			label: 'State',
			key: 'internal-state',
			type: 'string',
			constraints: required ? [{ type: 'required' }] : [],
		},
		{
			label: 'Country',
			key: 'internal-country',
			type: 'string',
			constraints: required ? [{ type: 'required' }] : [],
		},
		{
			label: 'Postal code/Zip',
			key: 'internal-postCode',
			type: 'string',
			constraints: required ? [{ type: 'required' }] : [],
		},
	];
};

export const getContactFields = ({
	isPhoneNumberRequired,
}: {
	isPhoneNumberRequired?: boolean;
}): FlatfileWorkbookFieldsType[] => {
	return [
		{
			label: 'First Name',
			key: 'internal-firstName',
			constraints: [{ type: 'required' }],
			type: 'string',
		},
		{
			label: 'Last Name',
			key: 'internal-lastName',
			constraints: [{ type: 'required' }],
			type: 'string',
		},
		{
			label: 'Email Address',
			key: 'internal-email',
			constraints: [{ type: 'required' }],
			description: 'Our default method to send videos.',
			type: 'string',
		},
		{
			label: 'Sending Method',
			type: 'enum',
			key: 'internal-communicationPreference',
			config: {
				options: [
					{ value: 'email', label: 'Email' },
					{ value: 'sms', label: 'SMS' },
					{ value: 'both', label: 'Both' },
				],
			},
			constraints: [{ type: 'required' }],
			description: 'Enter either email, sms or both.',
		},
		{
			label: 'Phone Number',
			key: 'internal-mobilePhoneNumber',
			type: 'string',
			constraints: [
				...(isPhoneNumberRequired
					? [
							{
								type: 'required',
								error: 'Please enter a phone number.',
							},
						]
					: []),
				{
					type: 'external',
					validator: 'mobilePhoneNumber',
					config: { regex: '^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9]*$' },
					error: 'Please enter a valid phone number.',
				},
			],
			description: !isPhoneNumberRequired
				? 'We use this to SMS your contacts.'
				: '',
		},
		{
			label: 'CRM ID',
			key: 'internal-crmId',
			type: 'string',
		},
		{
			label: 'Owner (Storyteller ID)',
			key: 'internal-owner',
			type: 'string',
			description:
				"The owner field is a reference to a storyteller. It can be either a custom ID that's unique to your company or the storyteller's ID.",
			constraints: [
				{
					type: 'external',
					validator: 'storytellerId',
					config: { regex: '^[0-9a-fA-F]{24}$' },
					error: 'Not a valid storyteller id',
				},
			],
		},
		{ label: 'Caption Language', key: 'prefferedLanguageCode', type: 'string' },
		{
			label: 'Campaign IDs',
			key: 'internal-campaigns',
			type: 'string',
			description:
				'Contacts will be added to campaigns upon import. Separate campaign ids with commas.',
		},
	];
};

export const storytellersFields: FlatfileWorkbookFieldsType[] = [
	{
		label: 'First Name',
		key: 'firstName',
		type: 'string',
		constraints: [{ type: 'required' }],
	},
	{
		label: 'Last Name',
		key: 'lastName',
		type: 'string',
		constraints: [{ type: 'required' }],
	},
	{
		label: 'Email Address',
		key: 'email',
		type: 'string',
		constraints: [{ type: 'required' }, { type: 'unique' }],
		description: 'Work email preferred.',
	},
	{
		label: 'Role',
		key: 'role',
		type: 'enum',
		config: {
			options: [
				{ value: 'admin', label: 'Admin' },
				{ value: 'recorder', label: 'Recorder' },
			],
		},
		constraints: [{ type: 'required' }],
		description: "Type 'admin' or 'recorder'",
	},
	{
		label: 'Title',
		key: 'title',
		type: 'string',
		description: 'Ex. Volunteer or Alumni',
	},
	{
		label: 'Phone Number',
		key: 'phoneNumber',
		type: 'number',
	},
	{
		label: 'Invite Message',
		key: 'message',
		type: 'string',
	},
	{
		label: 'External ID',
		key: 'externalId',
		type: 'string',
	},
];

export const flatFileTheme: IThemeConfig = {
	root: {
		fontFamily: GoodkindDSTheme.fonts.family.default,
		primaryColor: GoodkindDSTheme.colors.primary.background.default,
		buttonBorderRadius: GoodkindDSTheme.shapeRadius.regular,
		dangerColor: GoodkindDSTheme.colors.error.text.default,
		warningColor: GoodkindDSTheme.colors.warning.text.default,
		successColor: GoodkindDSTheme.colors.success.text.default,
	},
	table: {
		fontFamily: GoodkindDSTheme.fonts.family.default,
	},
};

export const flatFileTitles = {
	contacts: 'Bulk add contacts',
	contactsWithRequiredPhoneNumber: 'Bulk add contacts',
	postcardContacts: 'Bulk add contacts',
	postcardContactsWithRequiredPhoneNumber: 'Bulk add contacts',
	recorders: 'Bulk add recorders',
};

export const firstContactFieldsOffset = 5;

export const flatfileFields: Record<string, FlatfileWorkbookFieldsType[]> = {
	contacts: R.insertAll(
		firstContactFieldsOffset,
		[...getLocationFields()],
		[...getContactFields({ isPhoneNumberRequired: false })],
	),
	contactsWithRequiredPhoneNumber: R.insertAll(
		firstContactFieldsOffset,
		[...getLocationFields()],
		[...getContactFields({ isPhoneNumberRequired: true })],
	),
	postcardContacts: R.insertAll(
		firstContactFieldsOffset,
		[...getLocationFields(true)],
		[...getContactFields({ isPhoneNumberRequired: false })],
	),
	postcardContactsWithRequiredPhoneNumber: R.insertAll(
		firstContactFieldsOffset,
		[...getLocationFields(true)],
		[...getContactFields({ isPhoneNumberRequired: true })],
	),
	recorders: storytellersFields,
};

export const flatfileCustomAttributesFieldKeySuffix = '-ct';

export const recordHooks = {
	contacts: contactRecordChange,
	contactsWithRequiredPhoneNumber: contactRecordChange,
	postcardContacts: contactRecordChange,
	postcardContactsWithRequiredPhoneNumber: contactRecordChange,
	recorders: storytellerRecordChange,
};
