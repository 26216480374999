import styled from 'styled-components';

export const FlatfileUploaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const FlatfileSpaceWrapper = styled.div`
	width: 100% !important;
	z-index: 1030;
`;
