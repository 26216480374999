import { handlePlural } from '@app/utils/handlePlural';
import * as queryString from 'query-string';

import { operators, orderTypes } from '@app/constants';

import {
	CampaignStateEnum,
	campaignStatusType,
	CampaignType,
} from '@app/redux/campaign/types';
import { orderObject } from '@app/types';
import {
	checkIsCollectVideos,
	checkIsOneToMany,
	checkIsOneToOne,
	checkIsPostcard,
	checkIsScanToWatch,
	checkIsReels,
	checkIsSMS,
	checkIsWhatsapp,
} from '@app/utils/modules/campaigns';
import {
	AudienceHandlingTypeEnum,
	CampaignActionRequired,
	CampaignPersonalizationEnum,
	CampaignTypeDisplayName,
} from '@app/constants/modules/campaign';
import getStartEndDateFilter from '@app/utils/getStartEndDateFilter';
import { CreationMethod } from '../CampaignCreationV2/constants';
import { ApprovalsModes } from './constants';
import { formatDateAndTime } from '@app/utils/formatDate';
import { AudienceSourceEnum } from '@app/constants/modules/audience';

type FilterStructureProps = {
	page: number;
	status?: campaignStatusType;
	sort?: orderObject;
	search?: string;
	type?: CampaignPersonalizationEnum | null;
	creationMethod?: CreationMethod | null;
	audience?: string[] | null;
	teams?: string[] | null;
	createdAtDate?: {
		start: string | Date | null;
		end: string | Date | null;
	};
	scheduledDate?: {
		start: string | Date | null;
		end: string | Date | null;
	};
	sentOnDate?: {
		start: string | Date | null;
		end: string | Date | null;
	};
	actionRequiredTab?: boolean;
	requiredActionFilter?: string[] | null;
	recurringFilter?: boolean;
	readyToSendFilter?: boolean;
	activeTab?: boolean;
	createdBy?: string[] | null;
};

function getDefaultOrderByStatus(status?: campaignStatusType) {
	if (status === CampaignStateEnum.active) {
		return { field: 'launchedAt', type: orderTypes.descending };
	} else
		return {
			field: 'updatedAt',
			type: orderTypes.descending,
		};
}

function getFilterStructure({
	page = 1,
	status,
	sort,
	search,
	type,
	creationMethod,
	audience,
	teams,
	createdAtDate,
	scheduledDate,
	sentOnDate,
	requiredActionFilter,
	recurringFilter,
	readyToSendFilter,
	activeTab,
	createdBy,
}: FilterStructureProps) {
	const paginationObject = {
		page,
		pageSize: 15,
	};

	let orderObject;
	if (sort) {
		orderObject = sort;
	} else {
		orderObject = getDefaultOrderByStatus(status);
	}
	const populateObject = [
		{
			field: 'lastStoryWithVideo',
			select: ['video'],
			populate: {
				field: 'video',
				select: [
					'_id',
					'transcriptText',
					'thumbnail',
					'uploadedBy',
					'createdAt',
					'uploadedBy',
					'status',
				],
			},
		},
		{ field: 'callToActions', select: [] },
		{
			field: 'template',
			select: [],
		},
		{
			field: 'createdBy',
			select: ['firstName', 'lastName', 'fullName', 'profilePicture'],
		},
		{
			field: 'video',
			select: [
				'_id',
				'transcriptText',
				'thumbnail',
				'uploadedBy',
				'createdAt',
				'uploadedBy',
				'status',
			],
			populate: {
				field: 'uploadedBy',
				select: ['fullName', 'firstName', 'lastName', 'profilePicture'],
			},
		},
		{
			field: 'storytellers',
			select: [
				'firstName',
				'lastName',
				'fullName',
				'profilePicture',
				'emailVerified',
				'email',
				'role',
			],
		},
		{
			field: 'groups',
			select: ['name', 'value', 'storytellers'],
			populate: {
				field: 'storytellers',
				select: [
					'firstName',
					'lastName',
					'fullName',
					'profilePicture',
					'emailVerified',
					'email',
				],
			},
		},
		{
			field: 'audience',
			select: [
				'_id',
				'name',
				'size',
				'selectedContactLists',
				'lastSyncDate',
				'syncStatus',
				'filter',
				'source',
				'isSegment',
				'contactsWithVerifiedAddressCount',
				'verificationStatus',
				'metrics',
			],
			populate: {
				field: 'parentAudience',
				select: ['_id', 'name'],
			},
		},
		{
			field: 'assignedStories',
			select: [
				'id',
				'title',
				'video',
				'assignedTo',
				'instructions',
				'recordingScript',
				'storyOrder',
				'updatedAt',
				'createdAt',
			],
			populate: {
				field: 'assignedTo',
				select: [
					'firstName',
					'lastName',
					'fullName',
					'profilePicture',
					'email',
				],
			},
		},
		{
			field: 'team',
			select: ['id', 'icon', 'name'],
		},
		{
			field: 'completedStories',
			select: ['id', 'title', 'video', 'storyOrder'],
			populate: {
				field: 'video',
				select: [
					'transcriptText',
					'thumbnail',
					'uploadedBy',
					'createdAt',
					'status',
				],
				populate: {
					field: 'uploadedBy',
					select: ['fullName', 'firstName', 'lastName', 'profilePicture'],
				},
			},
		},

		{
			field: 'storiesPendingApproval',
			select: [
				'id',
				'title',
				'video',
				'storyOrder',
				'assignedTo',
				'contacts',
				'sendingTo',
			],

			populate: [
				{
					field: 'contacts',
					select: [
						'firstName',
						'lastName',
						'fullName',
						'profilePicture',
						'email',
					],
				},
				{
					field: 'video',
					select: [
						'transcriptText',
						'thumbnail',
						'uploadedBy',
						'createdAt',
						'status',
					],
					populate: {
						field: 'uploadedBy',
						select: ['fullName', 'firstName', 'lastName', 'profilePicture'],
					},
				},
			],
		},

		{
			field: 'automation',
			select: ['state', 'targetCampaign', 'metadata'],
		},
		{
			field: 'lastMessageSent',
			select: ['updatedAt'],
		},
	];

	const getTypeFilter = () => {
		if (type) {
			switch (type) {
				case CampaignPersonalizationEnum.collectVideos: {
					return [
						{
							field: 'personalization',
							value: CampaignPersonalizationEnum.oneToOne,
						},
						{ field: 'creationMethod', value: type },
					];
				}
				case CampaignPersonalizationEnum.oneToOne: {
					return [
						{ field: 'personalization', value: type },
						{ field: 'creationMethod', value: 'createToDos' },
					];
				}

				case CampaignPersonalizationEnum.post: {
					return [
						{ field: 'personalization', value: type },
						{ field: 'creationMethod', value: creationMethod || 'noVideo' },
					];
				}

				default:
					return [{ field: 'personalization', value: type }];
			}
		}

		return [];
	};

	const filterObject = [
		{ field: 'isChild', value: true, operator: operators.not },
		{ field: 'state', value: status },
		...(!!type ? getTypeFilter() : []),
		...(!!audience && audience.length > 0
			? [{ field: 'audience', operator: operators.in, value: audience }]
			: []),
		...(!!teams && teams.length > 0
			? [{ field: 'team', operator: operators.in, value: teams }]
			: []),
		...(!!createdAtDate && (!!createdAtDate.start || !!createdAtDate.end)
			? getStartEndDateFilter('createdAt', createdAtDate)
			: []),
		...(!!scheduledDate && (!!scheduledDate.start || !!scheduledDate.end)
			? getStartEndDateFilter('scheduleDate', scheduledDate)
			: []),
		...(!!sentOnDate && (!!sentOnDate.start || !!sentOnDate.end)
			? getStartEndDateFilter('lastStorySentAt', sentOnDate)
			: []),
		...(!!createdBy && createdBy.length > 0
			? [{ field: 'createdBy', operator: operators.in, value: createdBy }]
			: []),
		...(activeTab && requiredActionFilter && requiredActionFilter?.length > 0
			? [
					{
						field: 'actionsRequired',
						operator: operators.in,
						value: requiredActionFilter,
					},
				]
			: []),
		...(recurringFilter && recurringFilter === true
			? [
					{
						field: 'audienceHandlingType',
						operator: operators.in,
						value: AudienceHandlingTypeEnum.ADDITIVE,
					},
					{
						field: 'audienceSource',
						operator: operators.not,
						value: AudienceSourceEnum.UPLOAD,
					},
					{
						field: 'audience',
						operator: operators.not,
						value: null,
					},
				]
			: []),
		...(readyToSendFilter && readyToSendFilter === true
			? [
					{
						field: 'personalization',
						operator: operators.in,
						value: CampaignPersonalizationEnum.reels,
					},

					{
						field: 'isLive',
						operator: operators.in,
						value: false,
					},
					{
						field: 'actionsRequired',
						operator: operators.in,
						value: requiredActionFilter
							? [...requiredActionFilter, CampaignActionRequired.SET_AS_RUNNING]
							: [CampaignActionRequired.SET_AS_RUNNING],
					},
				]
			: []),
	];

	const searchObject = {
		fields: ['name'],
		value: search || '',
	};

	return {
		filter: filterObject,
		pagination: paginationObject,
		populate: populateObject,
		order: orderObject,
		search: search ? searchObject : undefined,
	};
}

function getExportFilterStructure() {
	const paginationObject = {
		page: 1,
		pageSize: 500,
	};

	const orderObject = { field: 'createdAt', type: orderTypes.descending };

	const populateObject = [
		{ field: 'callToAction', select: [] },
		{
			field: 'storytellers',
			select: [
				'firstName',
				'lastName',
				'fullName',
				'storiesSent',
				'assignedStories',
				'storiesSaved',
			],
		},
		{
			field: 'tags',
			select: ['text', 'color', '_id'],
		},
		{
			field: 'audience',
			select: ['_id', 'name'],
		},
	];

	return {
		pagination: paginationObject,
		populate: populateObject,
		order: orderObject,
	};
}

export { getFilterStructure, getExportFilterStructure };

export function handleConfirmationModalDescription(
	campaignsAmount: number,
): string {
	// campaign || campaigns
	const pluralStorytellerWord = handlePlural('campaign', campaignsAmount);

	return `Are you sure you want to delete ${campaignsAmount} draft ${pluralStorytellerWord}?`;
}

export function getCampaignDisplayType(campaign?: any, storie?: any) {
	const {
		creationMethod,
		personalization,
		whatsappEnabled,
		whatsappTemplateId,
		type,
	} = storie || campaign || {};

	const conditionsToDisplayTypes = [
		{
			condition: checkIsWhatsapp(
				whatsappEnabled,
				whatsappTemplateId,
				personalization,
			),
			displayType: CampaignTypeDisplayName.whatsapp,
		},
		{
			condition: checkIsOneToOne(campaign, creationMethod, personalization),
			displayType: CampaignTypeDisplayName.oneToOne,
		},
		{
			condition: checkIsOneToMany(campaign?.personalization || personalization),
			displayType: CampaignTypeDisplayName.oneToAll,
		},
		{
			condition: checkIsCollectVideos(
				campaign?.creationMethod || creationMethod,
			),
			displayType: CampaignTypeDisplayName.collectVideos,
		},
		{
			condition: checkIsReels(campaign?.personalization || personalization),
			displayType: CampaignTypeDisplayName.reels,
		},
		{
			condition: checkIsPostcard(campaign),
			displayType: CampaignTypeDisplayName.postcard,
		},
		{
			condition: checkIsScanToWatch(campaign),
			displayType: CampaignTypeDisplayName.scanToWatch,
		},
		{
			condition: checkIsSMS(campaign?.personalization || personalization),
			displayType: CampaignTypeDisplayName.SMS,
		},
		{
			condition: type === 'individual',
			displayType: CampaignTypeDisplayName.VideoMessage,
		},
	];

	const matchedCondition = conditionsToDisplayTypes.find(
		({ condition }) => condition,
	);

	return matchedCondition ? matchedCondition.displayType : '';
}

export function getCampaignIconByType(campaignType: any) {
	switch (campaignType) {
		case CampaignTypeDisplayName.VideoMessage:
			return 'videoCamera';
		case CampaignTypeDisplayName.oneToOne:
			return 'onetoonetype';
		case CampaignTypeDisplayName.oneToAll:
			return 'onetomanytype';
		case CampaignTypeDisplayName.collectVideos:
			return 'collecttype';
		case CampaignTypeDisplayName.reels:
			return 'reelstype';
		case CampaignTypeDisplayName.postcard:
			return 'postcard';
		case CampaignTypeDisplayName.scanToWatch:
			return 'scantowatch';
		case CampaignTypeDisplayName.SMS:
			return 'chat-circle-dots';
		case CampaignTypeDisplayName.whatsapp:
			return 'whatsapp-active';
		case CampaignTypeDisplayName.SmsMessage:
			return 'chatCenteredText';
		case CampaignTypeDisplayName.whatsappMessage:
			return 'whatsapp';
		default:
			return 'cube';
	}
}

export function getCampaignBadgeType(campaignType: any) {
	switch (campaignType) {
		case CampaignTypeDisplayName.oneToOne:
			return 'info';
		case CampaignTypeDisplayName.oneToAll:
			return 'highlight2';
		case CampaignTypeDisplayName.collectVideos:
			return 'highlight5';
		case CampaignTypeDisplayName.reels:
			return 'accent';
		case CampaignTypeDisplayName.SMS:
			return 'warning';
		case CampaignTypeDisplayName.postcard:
			return 'error';
		case CampaignTypeDisplayName.scanToWatch:
			return 'error';
		case CampaignTypeDisplayName.whatsapp:
			return 'success';
		case CampaignTypeDisplayName.VideoMessage:
		case CampaignTypeDisplayName.whatsappMessage:
		case CampaignTypeDisplayName.SmsMessage:
			return 'neutral';
		default:
			return 'info';
	}
}

export function pick(object: any, keys: string[]) {
	return keys.reduce((newObject: any, key: string) => {
		newObject[key] = object[key];
		return newObject;
	}, {});
}

export function queryParamsToObject(
	hash: string,
	selection: Array<string> | null = null,
) {
	if (!hash || !hash.includes('?')) {
		return {};
	}
	const search = `?${hash.split('?')[1]}`;
	let object = queryString.parse(search, { arrayFormat: 'comma' });
	if (selection?.length) {
		object = pick(object, selection);
	}
	return object;
}

export function checkIsPastDate(date: string): boolean {
	const isPastDate = new Date(date) < new Date();
	return isPastDate;
}

export function getApprovalsModalDescription(
	approvalMode: string,
	campaign?: CampaignType,
) {
	const scheduleDate = campaign?.scheduleDate;

	switch (approvalMode) {
		case ApprovalsModes.APPROVE_SEND:
			return `Approving this video will begin sending your campaign to the intended contacts. Would you like to approve?`;
		case ApprovalsModes.APPROVE_SCHEDULE:
			return `Approving this video will include it in the scheduled campaign set to send on ${scheduleDate && formatDateAndTime(scheduleDate, true)}. Would you like to approve this video?`;
		case ApprovalsModes.APPROVE_OUT_OF_SENDING_WINDOW:
			return `Approving this video will begin sending your campaign when the sending window opens. Would you like to approve?`;
		case ApprovalsModes.APPROVE_REEL_NOT_SENDING:
			return `Approving this video will add it as a new stop to the Reel. You can then start sending the Reel to contacts by clicking "Start sending Reel" in the latest activity column. Would you like to approve?`;
		case ApprovalsModes.APPROVE_REEL_ALREADY_SENDING:
			return `Approving this video will add it as a new stop to the currently sending Reel. The Reel will be updated and continue sending with this video included. Would you like to approve?`;
		case ApprovalsModes.APPROVE_COLLECT:
			return `Approving this video will save it to the video library, making it available for future video campaigns.`;
		default:
			return `Approving this video will begin sending your campaign to the intended contacts. Would you like to approve?`;
	}
}
