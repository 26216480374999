import { DateTime } from 'luxon';

const nth = (day: number): string => {
	if (day > 3 && day < 21) return 'th';

	switch (day % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};

const showTimeOptions = {
	month: 'long',
	day: 'numeric',
	year: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
};

// Returns in May 20, 2021 format
function formatDate(
	date: string | Date,
	shortMonth?: boolean,
	timezone?: string,
): FormatDateReturnType {
	const preFormattedDate = new Date(date);
	if (
		!(preFormattedDate instanceof Date && !isNaN(preFormattedDate.getTime()))
	) {
		return date;
	}

	const options = {
		month: shortMonth ? 'short' : 'long',
		day: 'numeric',
		year: 'numeric',
		...(timezone ? { timeZone: timezone } : {}),
	};

	return preFormattedDate.toLocaleDateString(
		'en-US',
		options as Intl.DateTimeFormatOptions,
	);
}

// Returns in May 20th, 2021 format
function formatDateMonthNominalDayYear(date: string): FormatDateReturnType {
	const preFormattedDate = new Date(date);
	if (
		!(preFormattedDate instanceof Date && !isNaN(preFormattedDate.getTime()))
	) {
		return date;
	}

	const fullDate = preFormattedDate.toLocaleDateString(
		'en-US',
		showTimeOptions as Intl.DateTimeFormatOptions,
	);

	const dateParts = fullDate.split(',');

	const day = dateParts[0].split(' ')[1];
	const month = dateParts[0].split(' ')[0];
	const year = dateParts[1].trim().split(' ')[0];

	return month + ' ' + day + nth(parseInt(day, 10)) + ', ' + year;
}

// Returns May 20, 2021, 7:40 PM format
function formatDateAndTime(
	date: string | Date,
	shortMonth?: boolean,
	timezone?: string,
): FormatDateReturnType {
	const preFormattedDate = new Date(date);
	if (
		!(preFormattedDate instanceof Date && !isNaN(preFormattedDate.getTime()))
	) {
		return date;
	}

	if (timezone) {
		const fromTimezoneDate = DateTime.fromJSDate(preFormattedDate);
		const toTimezoneDate = fromTimezoneDate.setZone(timezone);
		const dateFormat = `MMM${!shortMonth ? 'M' : ''} d, y`;
		return `${toTimezoneDate.toFormat(dateFormat)} at ${toTimezoneDate.toFormat('hh:mm a')}`;
	}

	const selectedOption = shortMonth
		? { ...showTimeOptions, month: 'short' }
		: showTimeOptions;

	return preFormattedDate.toLocaleDateString(
		'en-US',
		selectedOption as Intl.DateTimeFormatOptions,
	);
}

//	Returns May 20th, 7:40 PM format
function formatDateMonthNominalDayAndTime(
	date: string | Date,
	timezone: string | undefined = undefined,
): FormatDateReturnType {
	const preFormattedDate = new Date(date);
	if (
		!(preFormattedDate instanceof Date && !isNaN(preFormattedDate.getTime()))
	) {
		return date;
	}

	// Returns May 20th, 7:40pm format at the corresponding timezone
	if (timezone) {
		const fromTimezoneDate = DateTime.fromJSDate(preFormattedDate);
		const toTimezoneDate = fromTimezoneDate.setZone(timezone);
		const dayNumber = toTimezoneDate.day.toString() + nth(toTimezoneDate.day);
		return `${toTimezoneDate.toFormat('MMM')} ${dayNumber}, ${toTimezoneDate.toFormat('h:mma').replace('AM', 'am').replace('PM', 'pm')}`;
	}

	const fullDate = preFormattedDate.toLocaleDateString(
		'en-US',
		showTimeOptions as Intl.DateTimeFormatOptions,
	);

	const dateParts = fullDate.split(',');

	const day = dateParts[0].split(' ')[1];
	const month = dateParts[0].split(' ')[0].slice(0, 3);

	return (
		month +
		' ' +
		day +
		nth(parseInt(day, 10)) +
		' at ' +
		formatTimeAMPM(preFormattedDate)
	);
}

// Returns May 20th format
function formatDateMonthNominalDay(
	date: string,
	shortMonth?: boolean,
): FormatDateReturnType {
	const preFormattedDate = new Date(date);
	if (
		!(preFormattedDate instanceof Date && !isNaN(preFormattedDate.getTime()))
	) {
		return date;
	}

	const selectedOption = shortMonth
		? { ...showTimeOptions, month: 'short' }
		: showTimeOptions;

	const fullDate = preFormattedDate.toLocaleDateString(
		'en-US',
		selectedOption as Intl.DateTimeFormatOptions,
	);

	const dateParts = fullDate.split(',');

	const day = dateParts[0].split(' ')[1];
	const month = dateParts[0].split(' ')[0];

	return month + ' ' + day + nth(parseInt(day, 10));
}

// Returns May 20, 2021 format
function formatDateMonthDayYear(date: string | Date): FormatDateReturnType {
	const options = {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	};
	const preFormattedDate = new Date(date);
	return preFormattedDate.toLocaleDateString(
		'en-US',
		options as Intl.DateTimeFormatOptions,
	);
}

// Returns 6:30pm if has minutes, or 6:00pm if no minutes
function formatTimeAMPM(date: Date | string) {
	if (!date) return;

	const newDate = new Date(date);

	const hours = newDate.getHours();
	const minutes = newDate.getMinutes();

	const hoursRest = hours % 12;

	const hoursText = hoursRest ? hoursRest : 12; // the hour '0' should be '12'
	const minutesText = ('0' + minutes).slice(-2);

	const ampm = hours >= 12 ? 'PM' : 'AM';

	const formattedTime = `${hoursText}:${
		minutes !== 0 ? minutesText : '00'
	} ${ampm}`;

	return formattedTime;
}
function formatDateToRelativeTime(
	date: string | Date,
	short = true,
	showRemainingDaysForMonths = false,
) {
	const currentTime = new Date();
	const targetDate = new Date(date);
	const timeDifference = currentTime.getTime() - targetDate.getTime();
	const seconds = Math.floor(timeDifference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.ceil(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(days / 30);
	const remainingDaysInMonth = days - months * 30;

	if (seconds < 60) {
		return 'Just now';
	} else if (minutes < 60) {
		return `${minutes} ${short ? 'min' : 'minute'}${
			minutes > 1 ? 's' : ''
		} ago`;
	} else if (hours < 24) {
		return `${hours} ${short ? 'hr' : 'hour'}${hours > 1 ? 's' : ''} ago`;
	} else if (days < 7) {
		return `${days} ${short ? 'day' : 'day'}${days > 1 ? 's' : ''} ago`;
	} else if (weeks <= 4) {
		return `${weeks} ${short ? 'week' : 'week'}${weeks > 1 ? 's' : ''} ago`;
	} else {
		if (remainingDaysInMonth === 0 || !showRemainingDaysForMonths) {
			return `${months} ${short ? 'month' : 'month'}${
				months > 1 ? 's' : ''
			} ago`;
		} else {
			return `${months} ${short ? 'month' : 'month'}${
				months > 1 ? 's' : ''
			} and ${remainingDaysInMonth} ${short ? 'day' : 'day'}${
				remainingDaysInMonth > 1 ? 's' : ''
			} ago`;
		}
	}
}

function formatToISO(dateString: string): string | null {
	// Create a Date object from the input string
	const date = new Date(dateString);

	// Check if the date is valid
	if (isNaN(date.getTime())) {
		return null; // Return null for invalid dates
	}

	// Convert the date to an ISO 8601 string
	return date.toISOString();
}

export {
	formatDate,
	formatDateAndTime,
	formatDateMonthNominalDay,
	formatDateMonthNominalDayYear,
	formatDateMonthNominalDayAndTime,
	formatDateMonthDayYear,
	formatTimeAMPM,
	formatDateToRelativeTime,
	formatToISO,
};

type FormatDateReturnType = string | Date;
