export const growthbookFeatures = {
	WEB_DELIVERY: 'web_delivery',
	FRONTEND_CAMPAIGN_WIZARD_ADVANCE_SETTINGS:
		'frontend_campaign_wizard_advance_settings',
	FRONTEND_DUE_DATE: 'frontend_due_date',
	FRONTEND_SNAPCHAT_BACKGROUND: 'frontend_snapchat_bg_image',
	AUDIENCE_TYPE_TOGGLE: 'audience_type_toggle',
	AUDIENCE_TYPE_TOGGLE_1M: 'audience_type_toggle_1m',
	UNLAYER_TEMPLATE_EDITOR: 'unlayer_template_editor',
	INBOX: 'inbox_sms',
	BUILDER_V2: 'campaign_builder_v2',
	BUILDER_V2_ONE_TO_ONE: 'campaign_builder_v2_1:1',
	BUILDER_V2_ONE_TO_MANY: 'campaign_builder_v2_1:m',
	BUILDER_V2_REELS: 'campaign_builder_v2_reels',
	BUILDER_V2_SCAN_TO_WATCH: 'campaign_builder_v2_scan_to_watch',
	BUILDER_V2_POSTCARD: 'campaign_builder_v2_personalized_post',
	BUILDER_V2_OLD_BUILDER_LAUNCHER: 'campaign_builder_v2_old_builder',
	BUILDER_V2_POSTCARD_ORDER_SUMMARY: 'campaign_builder_postcard_order_summary',
	GK_ADMIN_TEMPLATE_EDITOR: 'gk-admin-template-editor',
	FRONTEND_SUCCESS_PLAN: 'frontend_success_plan',
	REELS_VIDEO_REPLIES: 'reels_video_replies',
	CONTACTS_APPROVAL_CRM: 'crm_contacts_approval_limit',
	FRONTEND_DATA_EXPORT_V2: 'frontend_data_export_v2',
	CAMPAIGN_FALLBACK: 'campaign_fallback',
	MMS_OVERLAY: 'frontend_mms_overlay',
	INBOX_MEDIA_MESSAGES: 'show_inbound_media',
	NOVU_NOTIFICATIONS: 'use_novu_notifications',
	TEMPLATE_MANAGER_SETTINGS: 'template-manager-settings',
	FRONTEND_CHATBOT_TOPICS: 'frontend_chatbot_topics',
	FRONTEND_CHATBOT_SUMMARY: 'frontend_chatbot_summary',
	ADDITIVE_AUDIENCE: 'additive_audience_front_end',
	USER_TEMPLATES_IN_CB: 'user_templates_in_cb',
	WHATSAPP_SMS_FALLBACK: 'whatsapp_sms_fallback',
	SENT_TAB_INBOX: 'sent_tab_inbox',
	ACTIVITY_V2: 'activity_v2',
	USER_TEMPLATE_WA_CREATION: 'user_template_wa_creation',
	INBOX_SEND_PREVIEW: 'inbox_send_preview',
	TIMEZONE_SWITCHER: 'timezone_switcher',
	HIDE_CHATBOT_UPDATE_SUGGESTIONS: 'hide_chatbot_update_suggestions',
	AUDIENCE_V3: 'audience_v3',
	MUX_PLAYER_THUMBNAIL_GIF: 'mux_player_thumbnail_gif',
	AUDIENCE_RULE_V2: 'audience_rule_v2',
	FRONTEND_CHATBOT_ALLOW_SUBDOMAINS: 'frontend_chatbot_allow_subdomains',
	MAP_STAGES: 'map_stages',
	FRONTEND_CONTACT_EXPORT: 'frontend_contact_export',
	FRONTEND_CHATBOT_EXTRA_MENU_LINKS: 'frontend_chatbot_extra_menu_links',
	FRONTEND_SCHEDULE_DMS: 'frontend_schedule_dms',
	CO_PILOT_SPAM_CHECKER: 'co-pilot-spam-checker',
	SETTINGS_TEAMS_INBOX_NOTIFICATIONS: 'settings_teams_inbox_notifications',
	FRONTEND_SAVE_INBOX_CAMPAIGN: 'frontend_save_inbox_campaign',
	CAMPAIGN_CAPTION_STYLING: 'campaign_caption_styling',
	FRONTED_INBOXES_DRAG_AND_PIN: 'frontend_inboxes_drag_and_pin',
	CONTACT_DETAILS_DRAWER_V2: 'contact_details_drawer_v2',
	META_WHATSAPP_CONNECT_BUTTON: 'meta_whatsapp_connect_button',
	FRONTEND_VALIDATE_CAMPAIGN_REPEATED_NAME:
		'frontend_validate_campaign_repeated_name',
	FRONTEND_INBOX_AUDIENCE_SELECTOR_V2: 'frontend_inbox_audience_selector_v2',
	BULK_LAUNCH_API_V2: 'bulk_launch_api_v2',
	AUDIENCES_V3_DUPLICATE_SEGMENTS: 'audiences_v3_duplicate_segment',
	AUDIENCES_V3_LIST_SORT: 'audiences_v3_list_sort',
	AUDIENCES_V3_SEGMENT_FOR_ALL: 'audiences_v3_segment_for_all',
};
