import React, { useMemo, useState } from 'react';
import { TableMapperType } from '../../types';
import { Badge } from '@common/design-system/components/atoms';
import { BadgePropsType } from '@common/design-system/components/atoms/Badge/Badge';
import { Popover } from '@common/design-system/components/molecules';
import styled from 'styled-components';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

export const ArrayWrapper = styled.div<{
	wrap?: boolean;
}>`
	display: flex;
	gap: ${({ theme }) => theme.spacing.xxxs};
	flex-wrap: inherit;
`;

function MultiBadgeCell({ mapperField, item }: PropsType) {
	const [showAllBadges, setShowAllBadges] = useState(false);

	const badgeMapperArray: BadgePropsType[] =
		typeof mapperField.badgeArray === 'function'
			? mapperField.badgeArray(item)
			: (mapperField.badgeArray ?? []);

	const cellPopoverContent =
		typeof mapperField.cellPopoverContent === 'function'
			? mapperField.cellPopoverContent(item)
			: mapperField.cellPopoverContent;

	const badgeArrayDisplayAmount =
		typeof mapperField.badgeArrayDisplayAmount === 'function'
			? mapperField.badgeArrayDisplayAmount(item)
			: (mapperField.badgeArrayDisplayAmount ?? badgeMapperArray.length);

	const badgesToDisplay = useMemo(() => {
		let badgesArrayAsserted;

		if (badgeMapperArray.length > badgeArrayDisplayAmount && !showAllBadges) {
			badgesArrayAsserted = badgeMapperArray.slice(0, badgeArrayDisplayAmount);
		} else {
			badgesArrayAsserted = badgeMapperArray;
		}

		return badgesArrayAsserted;
	}, [badgeMapperArray, badgeArrayDisplayAmount, showAllBadges]);

	const Cell = React.memo(function Cell() {
		return (
			<ArrayWrapper>
				{badgesToDisplay.map((badge, index) => (
					<Badge
						key={index}
						{...badge}
						onClick={
							!!badge?.onClick
								? () => badge.onClick && badge.onClick(item)
								: undefined
						}
					/>
				))}

				{badgeMapperArray.length > badgeArrayDisplayAmount &&
					!showAllBadges && (
						<>
							<Badge
								{...badgesToDisplay[badgeArrayDisplayAmount - 1]}
								iconName={undefined}
								text={`+${badgeMapperArray.length - badgeArrayDisplayAmount}`}
								onClick={() => setShowAllBadges(true)}
							/>
						</>
					)}
			</ArrayWrapper>
		);
	});

	if (cellPopoverContent) {
		return (
			<Popover
				withArrow
				openOnHover
				openOnClick={false}
				popOverContent={cellPopoverContent}
				openHoverDelay={200}
			>
				<Cell />
			</Popover>
		);
	}

	return <Cell />;
}

export default MultiBadgeCell;
