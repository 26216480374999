/* eslint-disable jsx-a11y/no-autofocus */
import React, { ChangeEvent, forwardRef, useRef } from 'react';

import {
	Asterisk,
	Icon,
	Label,
	SquareIconButton,
	Text,
} from '@common/design-system/components/atoms';

import * as S from './Input.styles';
import { IconWeight } from '@phosphor-icons/react';

type PropsType = {
	id: string;
	name: string;
	value: string;
	label?: string;
	description?: string;
	onChange?: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	onClick?: (
		event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	onFocus?: (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	onBlur?: (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	placeholder?: string;
	message?: string;
	hasError?: boolean;
	iconName?: string;
	iconColor?: string;
	iconWeight?: IconWeight;
	disabled?: boolean;
	textArea?: boolean;
	required?: boolean;
	size?: 'small' | 'medium';
	variant?: 'primary' | 'secondary';
	borderless?: boolean;
	readOnly?: boolean;
	type?: string;
	rows?: number;
	labelInfo?: string;
	leadingText?: string;
	endingText?: string;
	clearable?: boolean;
	onClear?: () => void;
	autoFocus?: boolean;
	[key: string]: any;
};

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, PropsType>(
	function InputComponent(
		{
			id,
			name,
			value,
			onChange,
			onClick,
			onFocus,
			onBlur,
			placeholder,
			label,
			description,
			message,
			hasError,
			iconName,
			iconColor,
			iconWeight,
			disabled,
			textArea,
			required,
			size = 'medium',
			readOnly,
			rows,
			type,
			variant,
			borderless,
			labelInfo,
			leadingText,
			endingText,
			clearable,
			onClear,
			autoFocus,
			...rest
		}: PropsType,
		ref: React.Ref<HTMLInputElement | HTMLTextAreaElement> | null,
	) {
		const textsColor = hasError ? 'error.text.default' : 'system.text.medium';
		const labelColor = hasError ? 'error.text.default' : 'system.text.default';

		const fieldRef =
			ref || useRef<HTMLInputElement | HTMLTextAreaElement>(null);

		const handleClear = () => {
			const field = (
				fieldRef as React.RefObject<HTMLInputElement | HTMLTextAreaElement>
			)?.current;

			if (onChange) {
				onChange({ target: { value: '' } } as ChangeEvent<
					HTMLInputElement | HTMLTextAreaElement
				>);
			}

			if (onClear) {
				onClear();
			}

			if (field) {
				field.focus();
				field.value = '';
			}
		};

		return (
			<S.MainWrapper>
				{(label || description) && (
					<S.LabelWrapper>
						{label && (
							<Label
								htmlFor={id}
								size="small"
								color={labelColor}
								tooltipInfo={labelInfo}
								mediumBold
							>
								{label}
								{required && <Asterisk />}
							</Label>
						)}
						{description && (
							<Text size="small" color={textsColor}>
								{description}
							</Text>
						)}
					</S.LabelWrapper>
				)}

				{textArea ? (
					<S.TextArea
						id={id}
						name={name}
						value={value}
						onChange={onChange}
						onClick={onClick}
						onFocus={onFocus}
						onBlur={onBlur}
						placeholder={placeholder}
						hasIcon={!!iconName}
						hasClearable={!!clearable}
						disabled={disabled}
						hasError={hasError}
						readOnly={readOnly}
						rows={rows}
						ref={fieldRef as React.Ref<HTMLTextAreaElement>}
						$variant={variant}
						$borderless={borderless}
						autoFocus={autoFocus}
						{...rest}
					/>
				) : (
					<S.InputWrapper>
						{!!iconName && (
							<S.IconWrapper>
								<Icon
									iconName={iconName}
									weight={iconWeight}
									size="medium"
									color={iconColor ?? 'neutral.icon.default'}
								/>
							</S.IconWrapper>
						)}

						{!!leadingText && (
							<S.LeadingTextWrapper>
								<Text color="system.text.weak">{leadingText}</Text>
							</S.LeadingTextWrapper>
						)}

						<S.Input
							id={id}
							name={name}
							value={value}
							onChange={onChange}
							onClick={onClick}
							onFocus={onFocus}
							onBlur={onBlur}
							placeholder={placeholder}
							hasIcon={!!iconName}
							hasClearable={!!clearable}
							hasLeadingText={!!leadingText}
							hasEndingText={!!endingText}
							disabled={disabled}
							hasError={hasError}
							$size={size}
							readOnly={readOnly}
							ref={fieldRef as React.Ref<HTMLInputElement>}
							$variant={variant}
							$borderless={borderless}
							type={type}
							autoFocus={autoFocus}
							{...rest}
						/>
						{!!clearable && (
							<S.ClearWrapper>
								<SquareIconButton
									ariaLabel="clear"
									variant="neutral"
									icon="close"
									customIconSize={15}
									onClick={handleClear}
								/>
							</S.ClearWrapper>
						)}

						{!!endingText && (
							<S.EndingTextWrapper>
								<Text color="system.text.weak" size="small">
									{endingText}
								</Text>
							</S.EndingTextWrapper>
						)}
					</S.InputWrapper>
				)}
				{message && (
					<Text size="small" lineHeight="large" color={textsColor}>
						{message}
					</Text>
				)}
			</S.MainWrapper>
		);
	} as React.ForwardRefRenderFunction<HTMLInputElement | HTMLTextAreaElement>,
);

export default Input;
export type { PropsType as InputPropsType };
