import { FlatfileRecord } from '@flatfile/plugin-record-hook';

import {
	COUNTRY_ISO_CODE,
	COUNTRY_PHONE_PREFIX,
	VIDEO_LANGUAGE_CODES,
} from '@app/constants/country';
import { CustomAttributeType } from '@app/types/modules/workspace';
import { emailRegex, phoneNumberRegex } from '@app/constants';
import { FlatfileWorkbookFieldsType } from '../types';
import { flatfileCustomAttributesFieldKeySuffix } from '../constants';

export function contactRecordChange(record: FlatfileRecord): FlatfileRecord {
	try {
		const country = record.get('internal-country') as string;
		const mobilePhoneNumber = record.get(
			'internal-mobilePhoneNumber',
		) as string;
		const email = record.get('internal-email') as string;
		const prefferedLanguageCode = record.get(
			'internal-prefferedLanguageCode',
		) as string;
		const communicationPreference = record.get(
			'internal-communicationPreference',
		) as string;

		// Metadata fields
		const metadata: any = record.getMetadata();
		const { fieldsByFormat } = metadata || {};

		// Generic error checking based on metadata fields
		if (Object.keys(fieldsByFormat)?.length > 0) {
			const dateFields = fieldsByFormat['date'];
			const numberFields = fieldsByFormat['number'];

			if (!!dateFields?.length) {
				for (let i = 0; i < dateFields?.length; i++) {
					const currentField = dateFields[i];
					const value = record.get(currentField) as string;
					if (value) {
						const isValidDate =
							/^\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/.test(value);
						const errorMessage = 'Value must be a date in YYYY-MM-DD format.';

						!isValidDate && record.addError(currentField, errorMessage);
					}
				}
			}

			if (!!numberFields?.length) {
				for (let i = 0; i < numberFields?.length; i++) {
					const currentField = numberFields[i];
					const value = record.get(currentField) as string;
					if (value) {
						const isValidDate = /^[0-9]*$/.test(value);
						const errorMessage = 'Value must be a number.';

						!isValidDate && record.addError(currentField, errorMessage);
					}
				}
			}
		}

		// Specific error checking
		if (country) {
			// Check if user's typing whole country or just code
			const fullCountrySubmitted = country.length > 2;
			let countryCode = '';
			// Look for country code based on input
			const countryFound = COUNTRY_ISO_CODE.find((c) =>
				fullCountrySubmitted
					? c.name.toLowerCase().includes(country.toLowerCase())
					: c.alpha2 === country.toUpperCase(),
			);
			if (countryFound) {
				countryCode = countryFound.alpha2;
				// Find phone prefix according to country code
				const numberPrefix = COUNTRY_PHONE_PREFIX.find(
					(c) => c.code === countryCode,
				)?.phoneCode;

				record.set('internal-country', countryCode);

				let formattedMobilePhoneNumber = '';

				if (mobilePhoneNumber) {
					formattedMobilePhoneNumber = numberPrefix + mobilePhoneNumber;
					// If user includes complete phone prefix format
					if (numberPrefix && mobilePhoneNumber.startsWith(numberPrefix)) {
						formattedMobilePhoneNumber = mobilePhoneNumber;
					} else if (
						// If user is missing "+" but has phone prefix
						numberPrefix &&
						mobilePhoneNumber.startsWith(numberPrefix.replace('+', ''))
					) {
						formattedMobilePhoneNumber = '+' + mobilePhoneNumber;
					}

					record.set('internal-mobilePhoneNumber', formattedMobilePhoneNumber);
				}
			} else {
				record.addError('internal-country', 'Invalid country.');
			}
		}

		if (email) {
			const isValidEmail = emailRegex.test(email);
			!isValidEmail &&
				record.addError(
					'internal-email',
					'Please enter a valid email address.',
				);
		}

		if (mobilePhoneNumber) {
			const isValidPhoneNumber = phoneNumberRegex.test(mobilePhoneNumber);
			const phoneNumberExceedsMax = mobilePhoneNumber.length > 15;

			if (phoneNumberExceedsMax || !isValidPhoneNumber)
				record.addError(
					'internal-mobilePhoneNumber',
					'Please enter a valid phone number.',
				);
		}

		if (prefferedLanguageCode) {
			let languageCode = '';

			const languageFound = VIDEO_LANGUAGE_CODES.find((l) =>
				l.name
					.toLowerCase()
					.replace(/([()])/g, '')
					.includes(prefferedLanguageCode.toLowerCase().replace(/([()])/g, '')),
			);

			if (languageFound) {
				languageCode = languageFound.name;

				record.set('internal-prefferedLanguageCode', languageCode);
			} else {
				record.addError('internal-prefferedLanguageCode', 'Invalid language.');
			}
		}
		if (communicationPreference) {
			const phoneErrorMessage =
				'Phone required because of sending method selected.';

			if (
				!mobilePhoneNumber &&
				(communicationPreference === 'sms' ||
					communicationPreference === 'both')
			) {
				record.addError('internal-mobilePhoneNumber', phoneErrorMessage);
			}
		}

		return record;
	} catch (err) {
		console.error('contactRecordChange err: ', err);
		throw err;
	}
}

export function storytellerRecordChange(
	record: FlatfileRecord,
): FlatfileRecord {
	try {
		const email = record.get('email') as string;
		const phoneNumber = record.get('phoneNumber') as string;

		if (email) {
			const isValidEmail = emailRegex.test(email);
			!isValidEmail &&
				record.addError('email', 'Please enter a valid email address.');
		}

		if (phoneNumber) {
			const isValidPhoneNumber = phoneNumberRegex.test(phoneNumber);
			const phoneNumberExceedsMax = phoneNumber.length > 15;

			if (phoneNumberExceedsMax || !isValidPhoneNumber)
				record.addError('phoneNumber', 'Please enter a valid phone number.');
		}

		return record;
	} catch (err) {
		console.error('storytellerRecordChange err: ', err);
		throw err;
	}
}

export function transformCustomAttributesIntoRows(
	customAttributes: CustomAttributeType[],
): FlatfileWorkbookFieldsType[] {
	if (!!!customAttributes?.length) return [];

	return customAttributes.reduce(
		(acc: FlatfileWorkbookFieldsType[], customAttribute) => {
			const attributeNameLowerCase = customAttribute.name.toLowerCase();

			// Check if the attributeName is already included
			const existingAttribute = acc.find((attr) =>
				attr.key.toLowerCase().includes(attributeNameLowerCase),
			);

			const fieldPerAttribute: FlatfileWorkbookFieldsType = {
				type: 'string',
				description: '',
				format: `${customAttribute.format}`,
				label: `${customAttribute.displayName}`,
				// Only append the sufix if the name already exists in the accumulator
				key: existingAttribute
					? `${customAttribute.name}${flatfileCustomAttributesFieldKeySuffix}`
					: `${customAttribute.name}`,
			};

			if (customAttribute.format === 'boolean') {
				fieldPerAttribute.type = 'boolean';

				fieldPerAttribute.description =
					'Optional. Must be either true or false.';
			}

			if (customAttribute.format === 'number') {
				fieldPerAttribute.type = 'number';
				fieldPerAttribute.description = 'Optional. Must be a number.';
			}

			if (customAttribute.format === 'date') {
				fieldPerAttribute.type = 'date';
				fieldPerAttribute.description = 'Optional. Must be a date.';
			}

			acc.push(fieldPerAttribute);
			return acc;
		},
		[],
	);
}

export function getCustomAttributesFieldsByFormat(
	customAttributes: CustomAttributeType[],
): { [key: string]: string[] } {
	if (!!!customAttributes?.length) return {};

	const result: { [key: string]: string[] } = {};
	customAttributes.forEach((customAttribute) => {
		const fieldType =
			customAttribute.format === 'text' ? 'string' : customAttribute.format;

		result[fieldType] = [
			...(result[customAttribute.format] || []),
			customAttribute.name,
		];
	});

	return result;
}
